export function getCountryBornFromCode(countryCode) {
  if (!countryCode) return null;

  const cc = countryCode.toLowerCase();
  if (cc === 'us') return 'usa';
  if (cc === 'ca') return 'canada';

  return 'none';
}

export function handleAddressAndCountryBorn(
  change,
  addressFieldName,
  addressObj,
  countryBornFieldName = 'country_born'
) {

  change(addressFieldName, addressObj);

  const newCountryBorn = getCountryBornFromCode(addressObj?.country_code);

  if (newCountryBorn && newCountryBorn !== 'none') {
    change(countryBornFieldName, newCountryBorn);
  } else {
    change(countryBornFieldName, 'none');
  }
}
