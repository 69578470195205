import React from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DataTable from 'components/DataTable/DataTable';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
import {
  getAnswersCountByAnswerType,
  isSomeReportQuestionFlagged,
  getAnswersCountByOpenAnswer
} from 'utils/SVRHelper';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// styles
import styles from './TableView.module.scss';

const cn = classnames.bind(styles);

const TableView = ({
  resources,
  isReportEditable,
  isLoading,
  onEdit,
  onReview,
  onDelete,
  onDownload,
}) => {
  const { openDropdown } = useDropdownActions();

  const renderDateColumn = (rowData) => {
    const { svr_categories, created_at } = rowData;
    return (
      <CustomColumn
        className={cn('date-column', { flagged: isSomeReportQuestionFlagged(svr_categories) })}
        label={<FormattedMessage id="general.date" />}
      >
        <Link onClick={onReview(rowData)} className="color-primary">
          {created_at ? moment(created_at, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}
        </Link>
      </CustomColumn>
    );
  };

  const renderReporterColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.reporter" />}>
      {rowData.user.full_name}
    </CustomColumn>
  );

  const renderPassesColumn = (rowData) => {
    const passesCount = getAnswersCountByAnswerType(rowData, 'pass');
    return (
      <CustomColumn label={<FormattedMessage id="general.passes" />}>
        {passesCount}
      </CustomColumn>
    );
  };

  const renderFailsColumn = (rowData) => {
    const failsCount = getAnswersCountByAnswerType(rowData, 'fail');
    return (
      <CustomColumn label={<FormattedMessage id="general.fails" />}>
        {failsCount}
      </CustomColumn>
    );
  };

  const renderNAColumn = (rowData) => {
    const NACount = getAnswersCountByAnswerType(rowData, 'not_available');
    return (
      <CustomColumn label={<FormattedMessage id="general.notAvail" />}>
        {NACount}
      </CustomColumn>
    );
  };

  const renderInputColumn = (rowData) => {
    const inputCount = getAnswersCountByOpenAnswer(rowData);
    return (
      <CustomColumn noBottomBorder label={<FormattedMessage id="general.fillBlank" />}>
        {inputCount}
      </CustomColumn>
    );
  };

  const handleDropdownOpen = (e, report) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.edit" />,
        onClick: onEdit(report),
        hide: !isReportEditable,
      },
      {
        label: <FormattedMessage id="general.button.download" />,
        onClick: onDownload(report),
      },
      {
        label: <FormattedMessage id="general.button.delete" />,
        onClick: onDelete(report),
      },
    ];
    openDropdown(e, options);
  };

  const renderActionsColumn = (report, { rowIndex }) => (
    <div className={cn('collapsible-value button-column', 'actions-column')}>
      <i
        data-name={`${report.name}-${report.id}-${rowIndex}`}
        className={cn('fa fa-dots-three-horizontal show-for-large', 'dropdown-icon')}
        onClick={(e) => handleDropdownOpen(e, report)}
      />
      {isReportEditable && (
        <Link onClick={onEdit(report)} className="button light hide-for-large">
          <FormattedMessage id="general.button.edit" />
        </Link>
      )}
      <Button
        red
        onClick={onDelete(report)}
        className="hide-for-large"
      >
        <FormattedMessage id="general.button.delete" />
      </Button>
      <Button
        blue
        onClick={onDownload(report)}
        className="hide-for-large"
      >
        <FormattedMessage id="general.button.download" />
      </Button>
    </div>
  );

  const columns = [
    { label: <FormattedMessage id="general.date" />, flex: '1 1 140px', renderer: renderDateColumn },
    { label: <FormattedMessage id="general.reporter" />, flex: '1 1 140px', renderer: renderReporterColumn },
    { label: <FormattedMessage id="general.passes" />, flex: '1 1 140px', renderer: renderPassesColumn },
    { label: <FormattedMessage id="general.fails" />, flex: '1 1 140px', renderer: renderFailsColumn },
    { label: <FormattedMessage id="general.notAvail" />, flex: '1 1 140px', renderer: renderNAColumn },
    { label: <FormattedMessage id="general.fillBlank" />, flex: '1 1 140px', renderer: renderInputColumn },
    { label: '', flex: '0 0 45px', fixed: true, renderer: renderActionsColumn },
  ];

  return (
    <DataTable
      data={resources}
      columns={columns}
      isLoading={isLoading}
      scrollable
    />
  );
};

TableView.propTypes = {
  resources: T.array.isRequired,
  isReportEditable: T.bool.isRequired,
  isLoading: T.bool.isRequired,
  onEdit: T.func.isRequired,
  onReview: T.func.isRequired,
  onDelete: T.func.isRequired,
  onDownload: T.func.isRequired,
};

export default TableView;
