import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './QuestionAnswerIcon.module.scss';

const cn = classnames.bind(styles);

const QuestionAnswerIcon = ({ questionIndex, answerValue, openAnswer }) => (
  <div className={cn('question-icon', openAnswer && answerValue ? 'fill_blank' : answerValue)}>
    {!answerValue && questionIndex + 1}
    {openAnswer && answerValue && <i className="fa fa-check" />}
    {!openAnswer && ['not_available', 'pass'].includes(answerValue) && <i className="fa fa-check" />}
    {!openAnswer && answerValue === 'fail' && <i className="fa fa-times" />}

  </div>
);

QuestionAnswerIcon.propTypes = {
  answerValue: T.string,
  questionIndex: T.number.isRequired,
  openAnswer: T.bool,
};

QuestionAnswerIcon.defaultProps = {
  answerValue: '',
  openAnswer: false,
};

export default QuestionAnswerIcon;
