import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import Panel from 'components/Panel';

function splitGenetics(genetics = []) {
  const maleGenetics = [];
  const femaleGenetics = [];

  genetics.forEach((item) => {
    const { genetic_type } = item.genetic || {};
    if (genetic_type === 'male') {
      maleGenetics.push(item);
    } else {
      femaleGenetics.push(item);
    }
  });

  return { maleGenetics, femaleGenetics };
}

const PigGroupGeneticsPanel = ({ pigGroup, updatePigGroup }) => {
  const { pig_group_genetics = [] } = pigGroup;

  const { maleGenetics, femaleGenetics } = splitGenetics(pig_group_genetics);

  const addGenetic = (data) => {
    updatePigGroup(
      { pig_group_genetics_attributes: [{ genetic_id: data.value }] },
      pigGroup.id
    );
  };

  const removeGenetic = (data) => {
    updatePigGroup(
      { pig_group_genetics_attributes: [{ id: data.id, _destroy: true }] },
      pigGroup.id
    );
  };

  const commonSelectProps = {
    optionKeys: { value: 'id', label: 'name' },
    labelRenderer: ({ name, provider }) => `${name} (${provider})`,
    extractLabel: ({ genetic: { name, provider } }) => `${name} (${provider})`,
    onSelect: addGenetic,
    onRemove: removeGenetic,
    placeholderKey: 'component.searchbox.placeholder',
    selectClassName: 'mt-10',
  };

  return (
    <Panel className="medium-12 xlarge-12 mb-15">
      <Panel.Heading title={<FormattedMessage id="general.genetics" />} />
      <Panel.Body className="ph-20">
        <div className="small-12 medium-6">
          <AsyncMultiSelect
            title={<FormattedMessage id="general.femaleGeneticOptional" />}
            values={femaleGenetics}
            optionsPath="/genetics?type=female"
            {...commonSelectProps}
          />
          <AsyncMultiSelect
            title={<FormattedMessage id="general.maleGeneticOptional" />}
            values={maleGenetics}
            optionsPath="/genetics?type=male"
            {...commonSelectProps}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

PigGroupGeneticsPanel.propTypes = {
  pigGroup: T.object.isRequired,
  updatePigGroup: T.func.isRequired,
};

export default PigGroupGeneticsPanel;
