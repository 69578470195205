import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import FeedOrderForm from './components/FeedOrderForm';
import SubmitOrderModal from './components/modals/SubmitOrderModal/SubmitOrderModal';
// endpoints
import { createFeedOrder } from 'endpoints/feedOrders';
import { getFarmBins } from 'endpoints/farms';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { withRouter } from 'react-router';

const CreateFeedOrder = ({
  pigGroup,
  setBreadcrumbs,
  setPageOptions,
  openModal,
  router: { params: { farmId, groupId }, push },
}) => {
  const [binsOptions, setBinsOptions] = useState([]);

  useEffect(() => {
    setPageOptions({ backLink: `/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders` });
    if (pigGroup && pigGroup.farm) {

      getFarmBins(pigGroup.farm.id)
        .then((bins) => setBinsOptions(bins))
        .catch(toastResponseErrors);
    }
  }, [farmId, groupId, pigGroup]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.feedOrders" />,
        path: '/feed-orders-farms',
      },
      {
        label: pigGroup?.farm?.name || '',
        path: `/feed-orders-farms/${farmId}/groups`,
      },
      {
        label: pigGroup?.name || '',
        path: `/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders`,
      },
      {
        label: <FormattedMessage id="general.createFeedOrder" />,
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [pigGroup?.name, farmId, groupId]);

  const onSubmit = async (newOrderData) => {
    try {
      await createFeedOrder(groupId, newOrderData);
      push(`/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders`);
      openModal(<SubmitOrderModal />);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const initialValues = {
    delivery_date: null,
    notes: '',
    feed_order_bins_attributes: [{ bin_id: null, quantity: 0 }]
  };

  return (
    <Fragment>
      <FeedOrderForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        binsOptions={binsOptions}
        backLink={`/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders`}
        title={<FormattedMessage id="general.feedOrder" />}
        submitLabel={<FormattedMessage id="general.createFeedOrder" />}
        farmName={pigGroup?.farm?.name}
        groupName={pigGroup?.name}
        openModal={openModal}
      />
    </Fragment>
  );
};

CreateFeedOrder.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openModal: T.func.isRequired,
  pigGroup: T.object,
  router: T.object.isRequired,
};

export default withRouter(connect(
  (state) => ({
    pigGroup: state.profiles.pigGroup.data,
  }), {
    setPageOptions,
    setBreadcrumbs,
    openModal,
  }
)(CreateFeedOrder));
