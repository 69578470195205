import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { reduxForm, formValueSelector, change } from 'redux-form';
import AddressSelect from '../../Admin/Farms/components/AddressSelect';
import Button from 'components/Button';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import FormErrorsBox from 'components/FormErrorsBox';
import Field from 'components/Field';
import FederalPremiseIDField from 'components/Admin/Farms/Fields/FederalPremiseIDField';
import FarmTypeField from 'components/Admin/Farms/Fields/FarmTypeField';
import FarmManagerField from 'components/Admin/Farms/Fields/FarmManagerField';
// utils, hooks
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { getFullAddress } from 'utils';
import useCountries from 'utils/hooks/useCountries';
import { handleAddressAndCountryBorn } from 'utils/countryBornHelper';
// assets
import styles from './SourceForm.module.scss';

const cn = classnames.bind(styles);

const customErrors = [{
  key: 'name',
  error: <FormattedMessage id="component.sourceForm.errors.name" />,
}];

const SourceEditForm = ({
  handleSubmit,
  pristine,
  submitting,
  farmAttributes,
  change,
  currentCompany,
}) => {
  const { countries } = useCountries();
  const selected = !isEmpty(farmAttributes.addressObj) ? getFullAddress(farmAttributes.addressObj) : '';

  const handleChangeAddress = (data) => {
    handleAddressAndCountryBorn(change, 'farm_attributes.addressObj', data, 'farm_attributes.country_born');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="small-12 medium-6 large-4 column">

        <div className="row">
          <div className="small-12 column">
            <FormErrorsBox formName="source-edit-form" customErrors={customErrors} />
          </div>
        </div>

        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                label={<FormattedMessage id="general.sourceName" />}
                name="farm_attributes.name"
                placeholderKey="general.typeAName"
              />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 column">
            <label>
              <AddressSelect
                label={<FormattedMessage id="general.address" />}
                hasPoweredByLabel
                selected={selected}
                countriesData={countries}
                changeValue={
                  (fieldName, val) => handleAddressAndCountryBorn(
                    change, `farm_attributes.${fieldName}`, val, 'farm_attributes.country_born'
                  )
                }
                placeholder={<FormattedMessage id="general.typeAddress" />}
              />
            </label>
          </div>
        </div>

        <FederalPremiseIDField className="row mt-15" name="farm_attributes.federal_premise_id" />
        <FarmTypeField className="mt-15" name="farm_attributes.farm_type" />
        <FarmManagerField name="farm_attributes.manager_id" currentCompany={currentCompany} />
      </div>

      <div className="small-12 medium-6 large-8 column">
        <div className={cn('map-block')}>
          <GoogleMapSelect
            onChangeAdress={handleChangeAddress}
            addressData={farmAttributes.addressObj}
          >
            {(position) => <FarmMarker {...position} farm={{ farm_type: farmAttributes.farm_type }} />}
          </GoogleMapSelect>
        </div>
      </div>

      <div className="small-12 column">
        {/* BUTTON */}
        <div className="row mt-5 mb-20">
          <div className="small-12 column">
            <Button
              primary
              type="submit"
              disabled={pristine || submitting || !farmAttributes.name}
            >
              <FormattedMessage id="general.button.saveChanges" />
            </Button>
            <Link to="/sources" className="button btn-link margin-left-1">
              <FormattedMessage id="general.button.cancel" />
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

SourceEditForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  isEditMode: T.bool,
  name: T.string,
  farmAttributes: T.object,
  currentCompany: T.object.isRequired,
};

const selector = formValueSelector('source-edit-form');

const formComponent = reduxForm({ form: 'source-edit-form' })(SourceEditForm);

export default connect(
  (state) => ({
    farmAttributes: selector(state, 'farm_attributes') || {},
    currentCompany: state.auth.user.current_company,
  }),
  { change }
)(formComponent);
