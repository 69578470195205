import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateFarm } from 'reducers/admin/farms';
// components
import { FormattedMessage } from 'react-intl';
import FarmEditForm from './components/FarmEditForm';
import Panel from 'components/Panel';
// utils
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const FarmEdit = ({ farm, params, updateFarm }) => {
  const getInitialValues = () => {
    if (farm.id === Number(params.id)) {
      return {
        auto_refresh_utc_offset: farm.auto_refresh_utc_offset,
        name: farm.name,
        manager_id: farm.manager_id,
        farm_type: farm.farm_type,
        external: farm.external,
        assign_external_source: farm.external_source_assigned,
        loose_sow_housed: farm.loose_sow_housed,
        country_born: farm.country_born || 'none',
        external_link_id: String(farm.external_link_id || ''),
        federal_premise_id: farm.federal_premise_id,
        utc_offset: farm.utc_offset,
        addressObj: {
          address: farm.address,
          city: farm.city,
          state: farm.state,
          country_code: farm.country_code,
          zipcode: farm.zipcode,
          lat: farm.lat,
          lng: farm.lng,
        },
      };
    }
    return null;
  };

  const updateFarmInfo = (data) => {
    const { id } = params;

    const { addressObj, ...rest } = data;

    return updateFarm({
      ...rest,
      ...addressObj,
      external_link_id: rest.external_link_id?.trim() ? rest.external_link_id : null,
    }, id)
      .then(() => {
        showToastrMessage('component.toastr.farm.infoUpdated');
      })
      .catch((response) => {
        toastResponseErrors(response, { external_link_id: `Error: ${response.errors?.external_link_id || 'Unknown'}` });
      });
  };

  return (
    <Panel>
      <Panel.Heading title={<FormattedMessage id="general.farmInformation" />} />
      <Panel.Body noPadding>
        <FarmEditForm onSubmit={updateFarmInfo} initialValues={getInitialValues()} />
      </Panel.Body>
    </Panel>
  );
};

FarmEdit.propTypes = {
  farm: T.object,
  updateFarm: T.func,
  params: T.object,
};

export default connect(
  (state) => ({
    farm: state.farms.resource.data,
    currentCompany: state.auth.user.current_company,
  }), {
    updateFarm,
  }
)(FarmEdit);
