import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { reportSVRQuestionAnswer } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Preloader from 'components/Preloader';
import UserAvatar from 'components/UserAvatar';
// utils
import classnames from 'classnames/bind';
import { renderMentionsText } from 'utils';
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './ReportSVRQuestionAnswerSegment.module.scss';

const cn = classnames.bind(styles);

const ReportSVRQuestionAnswerSegment = ({
  checkup,
  isDisabled,
  isSubmitting,
  paramsCheckupId,
  question,
  reportSVRQuestionAnswer,
  svrId,
  validateCheckup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { answer, description, item_key } = question;
  const isQuestionHasAnswer = !!answer?.last_dc_answered_on;
  const isCurrentQuestionAnswerSubmitting = isSubmitting && isLoading;
  const isOtherQuestionAnswerSubmitting = isSubmitting && !isLoading;

  const handleSVRAnswerChange = (answerValue) => {
    if (!validateCheckup(checkup)) return;
    setIsLoading(true);
    const updatedQuestion = {
      id: question.id,
      answer: {
        id: question.answer.id,
        value: answerValue,
        is_flagged: answerValue === 'pass' ? false : question.answer.is_flagged,
      }
    };

    reportSVRQuestionAnswer(paramsCheckupId, svrId, { questions: [updatedQuestion] })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
        if (isEdit) setIsEdit(false);
      });
  };

  return (
    <div className={cn('card-wrapper', { 'is-disabled': isDisabled || isOtherQuestionAnswerSubmitting })}>
      <Preloader isCentered isActive={isCurrentQuestionAnswerSubmitting} />
      {/* header line */}
      <div className={cn('header-line', { active: !isQuestionHasAnswer })}>
        <FormattedMessage id="general.reportFollowup" />
      </div>

      {/* body */}
      <div className={cn('site-visit-report-card', { answered: isQuestionHasAnswer })}>
        <div className={cn('card-content')}>
          <div className={cn('svr-status')}>
            {isQuestionHasAnswer && (
              <div className={cn('answer-icon', answer.value)}>
                {answer.value === 'pass'
                  ? <i className="fa fa-check-circle-bts" />
                  : <i className="fa fa-times-circle" />
                }
              </div>
            )}
            {!isQuestionHasAnswer && <div className={cn('circle')} />}
          </div>
          <div className={cn('text-section')}>
            <div className={cn('question')}>
              {item_key}
              {isQuestionHasAnswer && (
                <Fragment>
                  <DotSeparator />
                  {answer.value === 'pass' ?
                    <FormattedMessage id="general.button.yes" /> :
                    <FormattedMessage id="general.button.no" />
                  }
                </Fragment>
              )}
            </div>
            <div className={cn('question-desc')}>{description}</div>
          </div>
          <div className={cn('btn-group')}>
            {isEdit || !isQuestionHasAnswer ? (
              <Fragment>
                <Button
                  onClick={() => handleSVRAnswerChange('fail')}
                  className={cn('btn', 'action-btn')}
                  light
                >
                  <FormattedMessage id="general.button.no" />
                </Button>
                <Button onClick={() => handleSVRAnswerChange('pass')} className={cn('btn', 'action-btn')} light>
                  <FormattedMessage id="general.button.yes" />
                </Button>
              </Fragment>
            ) : (
              <Button onClick={() => setIsEdit(true)} className={cn('btn')} light>
                <FormattedMessage id="general.button.edit" />
              </Button>
            )}
          </div>
        </div>

        {/* comments */}
        {!!answer?.comments.length && (
          <div className={cn('comments-section')}>
            {answer.comments.map(({ user, comment }, idx) => (
              <div key={idx} className={cn('comment')}>
                <UserAvatar user={user} size={35} />
                <div className={cn('comment-body')}>
                  <span className="semibold">{user.full_name}</span>
                  &nbsp;
                  {renderMentionsText(comment)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ReportSVRQuestionAnswerSegment.propTypes = {
  checkup: T.object.isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  paramsCheckupId: T.string.isRequired,
  question: T.object.isRequired,
  reportSVRQuestionAnswer: T.func.isRequired,
  svrId: T.number.isRequired,
  validateCheckup: T.func.isRequired,
};

export default connect(
  ({ dailyCheckup: { checkupEdit } }) => ({
    isSubmitting: checkupEdit.isReportingSVRQuestionAnswer,
    isDisabled: isSomeSectionReporting(checkupEdit) && !checkupEdit.isReportingSVRQuestionAnswer,
  }), {
    reportSVRQuestionAnswer,
  }
)(ReportSVRQuestionAnswerSegment);
