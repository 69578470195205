import React from 'react';
import { FormattedMessage } from 'react-intl';

export const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.farmname" /> },
];

export const requiredFarmFields = ['auto_refresh_utc_offset', 'external', 'external_link_id', 'assign_external_source',
  'name', 'farm_type', 'user_role', 'manager_id', 'utc_offset', 'want_user', 'federal_premise_id'];

export const requiredSUFarmFields = [
  ...requiredFarmFields, 'farm_genetics_attributes', 'loose_sow_housed', 'country_born'
];

export const farmsUserRoles = [
  {
    value: 'owner',
    label: <FormattedMessage id="general.role.farmOwner" />,
    description: <FormattedMessage id="component.createFarmForm.userRoles.ownerDesc" />,
  },
  {
    value: 'caretaker',
    label: <FormattedMessage id="general.role.caregiver" />,
    description: <FormattedMessage id="component.createFarmForm.userRoles.caregiverDesc" />,
  },
  {
    value: 'manager',
    label: <FormattedMessage id="general.role.farmManager" />,
    description: <FormattedMessage id="component.createFarmForm.userRoles.managerDesc" />,
  },
];

export const addressRadioOptions = [
  { value: 'address', label: <FormattedMessage id="general.address" /> },
  { value: 'coords', label: <FormattedMessage id="general.geoCoordinates" /> },
];

export const countryBornOptions = [
  { value: 'none', label: <FormattedMessage id="general.none" /> },
  { value: 'usa', label: <FormattedMessage id="general.countryBorn.usa" /> },
  { value: 'canada', label: <FormattedMessage id="general.countryBorn.canada" /> },
];
