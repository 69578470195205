import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Treatment from './Treatment';
import Field from 'components/Field';
import { reduxForm, change, initialize, FieldArray, getFormValues } from 'redux-form';
import CommentBox from 'components/CommentBox';
import Divider from 'components/Divider';
import CircleCheckbox from 'components/CircleCheckbox';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import MessageBox from 'components/MessageBox';
// constants
import { treatmentProtocolFormName } from './helper';
// endpoints
import { getTreatments as getMedications } from 'endpoints/treatmentProducts';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { formatTreatmentProtocolResponse, validateTreatmentProtocol } from '../../helper';
import { prepareTreatmentsList } from 'utils';
// endpoints
import { fetchFarmTreatmentProtocol, fetchPigGroupTreatmentProtocol } from 'endpoints/treatmentProtocols';
// styles
import styles from './TreatmentProtocolForm.module.scss';

const initialTreatment = { steps: [{}] };

const filterOption = (selectedEntities) => (option) => {
  const isAlreadySelected = !!selectedEntities.find((entity) => entity.id === option.value);
  if (isAlreadySelected) return null;
  return option;
};

const farmProtocolLabelRenderer = ({ name, farm }) => `${name} (${farm.name})`;

const renderTreatments = ({
  fields,
  options,
  treatments,
  isProductsChangeable,
  ABFEnabled,
} = {}) => fields.map((fieldName, fieldIndex) => (
  <Treatment
    isProductsChangeable={isProductsChangeable}
    product={treatments?.[fieldIndex]?.product}
    key={fieldName}
    options={options}
    fieldName={fieldName}
    onRemove={fields.length > 1 ? () => fields.remove(fieldIndex) : undefined}
    ABFEnabled={ABFEnabled}
  />
));

const TreatmentProtocolForm = ({
  title,
  formValues = {},
  change,
  onSubmit,
  submitLabel,
  isProductsChangeable = true,
  isNameChangeable = true,
  validate = validateTreatmentProtocol,
  initialValues,
  initialize,
  isCreate = false,
  isGlobal = false,
  isApplicable = false,
  isBaseDateDisabled = false,
  ABFEnabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [medications, setMedications] = useState([]);

  const addTreatment = () => change('treatments', [...formValues.treatments, initialTreatment]);
  const resetData = () => change('treatments', [initialTreatment]);

  useEffect(() => {
    getMedications()
      .then(prepareTreatmentsList)
      .then(setMedications)
      .catch(toastResponseErrors)
      .finally(() => setIsLoading(false));
  }, []);

  const getTreatmentProductSelectOptions = () => medications.map((medication) => ({
    ...medication,
    disabled: formValues.treatments?.some(({ product }) => product?.id === medication.id),
  }));

  const isFormValid = validate(formValues, initialValues);

  const onCommentChange = (comment) => change('comment', comment);

  const onProtocolUseValueChange = (useType, checked) => {
    change('protocol_use_type', checked ? useType : '');
  };

  const onProtocolBaseDateTypeChange = ({ target: { checked } }) => {
    change('tp_base_date', checked);
  };

  const onProtocolApplyTypeChange = ({ target: { checked } }) => {
    change('update_with_groups', checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  const onFarmsSelect = ({ item }) => {
    change('applied_farms', [...formValues.applied_farms, item]);
  };

  const onFarmRemove = (item) => {
    change('applied_farms', [...formValues.applied_farms.filter((option) => option.id !== item.id)]);
  };

  const useFarmProtocol = async (selectedFarmProtocol) => {
    try {
      setIsLoading(true);
      const farmProtocol = await fetchFarmTreatmentProtocol(selectedFarmProtocol.value);
      const formattedFarmProtocol = formatTreatmentProtocolResponse(farmProtocol);
      initialize({
        ...formattedFarmProtocol,
        id: undefined,
        treatments: formattedFarmProtocol.treatments.map((treatment) => ({
          ...treatment,
          id: undefined,
          steps: treatment.steps.map((step) => ({ ...step, id: undefined })),
        })),
        protocol_use_type: formValues.protocol_use_type,
        used_farm_protocol: selectedFarmProtocol,
        name: formValues.name || formattedFarmProtocol.name,
        applied_farms: formValues.applied_farms || [],
      });
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const useGroupProtocol = async (selectedGroupProtocol) => {
    try {
      setIsLoading(true);
      const groupProtocol = await fetchPigGroupTreatmentProtocol(selectedGroupProtocol.value);
      const formattedGroupProtocol = formatTreatmentProtocolResponse(groupProtocol);
      initialize({
        ...formattedGroupProtocol,
        id: undefined,
        treatments: formattedGroupProtocol.treatments.map((treatment) => ({
          ...treatment,
          id: undefined,
          steps: treatment.steps.map((step) => ({ ...step, id: undefined })),
        })),
        protocol_use_type: formValues.protocol_use_type,
        used_group_protocol: selectedGroupProtocol,
        name: formValues.name || formattedGroupProtocol.name,
        applied_farms: formValues.applied_farms || [],
      });
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className="small-12 column">
        <Panel className="mb-20">
          <Panel.Heading title={title} />
          <Panel.Body className="ph-5">
            <Preloader isActive={isLoading} />
            <div className={styles['treatment-protocol-form']}>
              <div className="medium-4 ph-15">
                <FormattedMessage id="general.typeProtocolName">
                  {(placeholder) => (
                    <Field
                      disabled={!isNameChangeable}
                      name="name"
                      label={<FormattedMessage id="general.treatmentProtocolName" />}
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </div>

              {isCreate && (
                <div className="medium-4 ph-15 mb-20">
                  <div className="mb-5">
                    <CircleCheckbox
                      label={(
                        <span className="semibold">
                          <FormattedMessage id="general.useExistingFarmProtocol" />
                        </span>
                      )}
                      checked={Boolean(formValues.protocol_use_type === 'farm')}
                      className="mb-10"
                      onChange={({ target: { checked } }) => onProtocolUseValueChange('farm', checked)}
                    />
                  </div>
                  {formValues.protocol_use_type === 'farm' && (
                    <AsyncSelect
                      selected={formValues.used_farm_protocol || {}}
                      labelRenderer={farmProtocolLabelRenderer}
                      onChange={useFarmProtocol}
                      optionsPath="/farm_treatment_protocols"
                      optionsKeys={{ value: 'id', label: 'name' }}
                      placeholder={<FormattedMessage id="general.selectTreatmentProtocol" />}
                    />
                  )}
                  <div className="mb-5">
                    <CircleCheckbox
                      label={(
                        <span className="semibold">
                          <FormattedMessage id="general.useExistingPigGroupProtocol" />
                        </span>
                      )}
                      checked={Boolean(formValues.protocol_use_type === 'group')}
                      onChange={({ target: { checked } }) => onProtocolUseValueChange('group', checked)}
                      className="mb-10"
                    />
                  </div>
                  {formValues.protocol_use_type === 'group' && (
                    <AsyncSelect
                      selected={formValues.used_group_protocol || {}}
                      onChange={useGroupProtocol}
                      optionsPath="/pig_group_treatment_protocols"
                      optionsKeys={{ value: 'id', label: 'name' }}
                      placeholder={<FormattedMessage id="general.selectTreatmentProtocol" />}
                    />
                  )}
                </div>
              )}

              <div className={styles['protocol-comment']}>
                <CommentBox
                  label={<FormattedMessage id="general.addTreatmentProtocolNote" />}
                  className="no-margin"
                  onValueChange={onCommentChange}
                  value={formValues.comment}
                />
              </div>

              {isApplicable && (
                <>
                  <Divider className="mh-15 mv-15" />
                  <div className="medium-6 ph-15">
                    <CircleCheckbox
                      label={(
                        <span className="semibold">
                          <FormattedMessage id="general.applyChangesToAllTreatmentProtocols" />
                        </span>
                      )}
                      subLabel={<FormattedMessage id="general.applyChangesToAllTreatmentProtocols.desc" />}
                      checked={Boolean(formValues.update_with_groups)}
                      className="mb-10"
                      onChange={onProtocolApplyTypeChange}
                    />
                  </div>
                </>
              )}

              {isGlobal && (
                <>
                  <Divider className="mh-15 mv-15" />
                  <div className={styles['apply-section']}>
                    <div className={styles.label}>
                      <FormattedMessage
                        tagName="b"
                        id="general.applyTreatmentProtocolForFarms"
                      />
                      <p className={styles['label-description']}>
                        <FormattedMessage
                          id="general.applyTreatmentProtocolForFarms.desc"
                        />
                      </p>
                      <div>
                        <AsyncMultiSelect
                          values={formValues.applied_farms}
                          filterOption={filterOption}
                          scrollable
                          className={styles['multi-select']}
                          selectClassName={cn('mt-10')}
                          onSelect={onFarmsSelect}
                          requestParams={{ active: true, type: ['su', 'n', 'wf', 'f'] }}
                          optionsPath="/farms/search"
                          onRemove={onFarmRemove}
                          optionKeys={{ value: 'id', label: 'name' }}
                          placeholderKey="general.searchBy.farms"
                          extractLabel={(value) => value.name}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <Divider className="mh-15 mv-15" />
              <div className="medium-8 ph-15 mv-20">
                <CircleCheckbox
                  label={(
                    <>
                      <span className="semibold">
                        <FormattedMessage id="general.applyOnArrival" />
                      </span>
                      <div className="description">
                        <FormattedMessage id="general.treatmentProtocolWillBeApplied" />
                      </div>
                    </>
                  )}
                  disabled={isBaseDateDisabled}
                  checked={Boolean(formValues.tp_base_date === true)}
                  onChange={onProtocolBaseDateTypeChange}
                />
                <div className="mt-10">
                  <MessageBox type="warning" className="message-box">
                    <FormattedMessage id="general.treatmentProtocolApplyOnArrival.warning" />
                  </MessageBox>
                </div>
              </div>
              <Divider className="mh-15 mv-15" />
              <FieldArray
                name="treatments"
                component={renderTreatments}
                options={getTreatmentProductSelectOptions()}
                treatments={formValues.treatments}
                isProductsChangeable={isProductsChangeable}
                ABFEnabled={ABFEnabled}
              />
              <div className={cn(styles['action-btn-group'], 'ph-15')}>
                {isProductsChangeable && (
                  <>
                    <Button type="button" onClick={addTreatment} blue>
                      <FormattedMessage id="general.addProduct" />
                    </Button>
                    <Button type="button" className={styles['clear-btn']} onClick={resetData}>
                      <FormattedMessage id="general.button.clearAll" />
                    </Button>
                  </>
                )}
                <Button disabled={!isFormValid} primary className={styles['submit-btn']}>
                  {submitLabel}
                </Button>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    </form>
  );
};

TreatmentProtocolForm.propTypes = {
  initialValues: T.object,
  title: T.node.isRequired,
  formValues: T.object,
  change: T.func.isRequired,
  onSubmit: T.func.isRequired,
  submitLabel: T.node.isRequired,
  validate: T.func,
  isProductsChangeable: T.bool,
  isNameChangeable: T.bool,
  initialize: T.func.isRequired,
  isCreate: T.bool,
  isGlobal: T.bool,
  isApplicable: T.bool,
  isBaseDateDisabled: T.bool,
  ABFEnabled: T.bool,
};

const formComponent = reduxForm({ form: treatmentProtocolFormName, enableReinitialize: true, })(TreatmentProtocolForm);

export default connect((state) => ({
  formValues: getFormValues(treatmentProtocolFormName)(state),
}), { change, initialize })(formComponent);
