import moment from 'moment';

export function getNextBusinessDay(startDate = moment()) {
  const dayOfWeek = startDate.day();
  if (dayOfWeek >= 1 && dayOfWeek <= 4) {
    return startDate.clone().add(1, 'days');
  }
  if (dayOfWeek === 5) {
    return startDate.clone().add(3, 'days');
  }
  if (dayOfWeek === 6) {
    return startDate.clone().add(2, 'days');
  }
  return startDate.clone().add(1, 'days');
}
