import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
import { compose } from 'recompose';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
// style
import rocketImage from './rocket.svg';
import checkImage from './check.svg';
import './SubmitOrderModal.scss';

const CheckMark = () => (
  <div className="SubmitOrderModal__checkWrapper">
    <img src={checkImage} alt="Feed Order updated!" />
  </div>
);

const renderImage = (isUpdate) => {
  return isUpdate ? (
    <CheckMark />
  ) : (
    <img src={rocketImage} alt="Feed Order submitted!" />
  );
};

const renderText = (isUpdate) => {
  return isUpdate ? (
    <FormattedMessage tagName="p" id="component.modal.success.feedOrder.text.update" />
  ) : (
    <FormattedMessage tagName="p" id="component.modal.success.feedOrder.text" values={{ break: <br /> }} />
  );
};

const SubmitOrderModal = ({ closeModal, isUpdate = false }) => (
  <Modal className="SubmitOrderModal">
    <section className="modal-body">
      {!isUpdate && <FormattedMessage tagName="h1" id="component.modal.success.title" />}
      {renderImage(isUpdate)}
      {renderText(isUpdate)}
    </section>
    <section className="modal-footer flex sticky-on-mobile">
      <Button light onClick={closeModal}>
        <FormattedMessage id="general.button.ok" />
      </Button>
    </section>
  </Modal>
);

SubmitOrderModal.propTypes = {
  closeModal: T.func.isRequired,
  isUpdate: T.bool,
};

const enhance = compose(
  connect(null, { closeModal }),
  withSoundOnMount('/static/sounds/success_sound.wav', 0.3),
);

export default enhance(SubmitOrderModal);
