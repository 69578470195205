import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SourceForm from './components/SourceForm';
import SourceCommingledForm from './components/SourceCommingledForm';
import Panel from 'components/Panel';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
// utils
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
// sources api
import { createSource } from 'endpoints/sources';
// styles
import './SourceCreate.scss';

const radioButtonOptions = [
  { value: 'external', label: <FormattedMessage id="component.sourceCreate.newSource" /> },
  { value: 'commingled', label: <FormattedMessage id="component.sourceCreate.newCommingledSource" /> },
];

const initialValues = { name: '', farm_attributes: {} };
const initialValuesCommingled = { name: '', sources: [{}] };

const SourceCreate = (props, { router }) => {
  const [sourceType, setSourceType] = useState('external');

  const formatFarmAttributes = ({ name, farm_attributes }) => {
    if (farm_attributes.id) return { id: farm_attributes.id };
    return {
      ...pick(farm_attributes, ['id', 'farm_type', 'manager_id', 'federal_premise_id', 'country_born']),
      ...farm_attributes.addressObj,
      name,
    };
  };

  const handleCreateSource = (data) => {
    const sendData = sourceType === 'external'
      ? { farm_attributes: formatFarmAttributes(data) }
      : data;
    return createSource(sendData)
      .then(() => {
        showToastrMessage('component.toastr.source.created');
        router.push('/sources');
      })
      .catch(handleFormErrors);
  };

  return (
    <section className="SourceCreate small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="component.sourceCreate.createExternalSource" />} />
        <Panel.Body noPadding>
          <div className="row">
            <div className="small-12 medium-8 column mt-20">
              <ButtonRadioGroup
                options={radioButtonOptions}
                value={sourceType}
                onChange={({ target: { value } }) => setSourceType(value)}
                className="source-option-types"
              />
            </div>
          </div>
          <div className="row">
            {sourceType === 'commingled'
              ? <SourceCommingledForm onSubmit={handleCreateSource} initialValues={initialValuesCommingled} />
              : <SourceForm onSubmit={handleCreateSource} initialValues={initialValues} />
            }
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

SourceCreate.contextTypes = {
  router: T.object.isRequired,
};

export default SourceCreate;
