import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { reduxForm, formValueSelector, change } from 'redux-form';
import AddressSelect from '../../Admin/Farms/components/AddressSelect';
import Button from 'components/Button';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import FarmAutosuggestField from 'components/ReduxFormFields/FarmAutosuggestField/FarmAutosuggestField';
import FormErrorsBox from 'components/FormErrorsBox';
import Field from 'components/Field';
import FederalPremiseIDField from 'components/Admin/Farms/Fields/FederalPremiseIDField';
import FarmTypeField from 'components/Admin/Farms/Fields/FarmTypeField';
import FarmManagerField from 'components/Admin/Farms/Fields/FarmManagerField';
import MessageBox from 'components/MessageBox';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { getFullAddress } from 'utils';
import useCountries from 'utils/hooks/useCountries';
import { handleAddressAndCountryBorn } from 'utils/countryBornHelper';
// assets
import styles from './SourceForm.module.scss';

const cn = classnames.bind(styles);

const customErrors = [{
  key: 'name',
  error: <FormattedMessage id="component.sourceForm.errors.name" />,
}];

const SourceForm = ({
  handleSubmit,
  pristine,
  submitting,
  name,
  farmAttributes,
  change,
  currentCompany,
}) => {
  const { countries } = useCountries();
  const isNewFarm = !!name && !farmAttributes.id;
  const isExistingFarm = !!name && !!farmAttributes.id;
  const selected = !isEmpty(farmAttributes.addressObj) ? getFullAddress(farmAttributes.addressObj) : '';

  useEffect(() => {
    if (currentCompany.id) change('farm_attributes', { manager_id: currentCompany.id });
  }, [currentCompany.id]);

  const handleNameChange = (e, { newValue }) => {
    change('name', newValue);
  };

  const setFarmAttributes = (farm) => {
    if (isEmpty(farm)) {
      change('farm_attributes', { manager_id: currentCompany.id });
      return;
    }
    change('farm_attributes', { id: farm.id, external_source_assigned: farm.external_source_assigned });
  };

  const changeAddress = (data) => {
    handleAddressAndCountryBorn(change, 'farm_attributes.addressObj', data, 'farm_attributes.country_born');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={cn(`small-12 ${isNewFarm ? 'medium-6 large-4' : 'medium-8'} column`)}>

        <div className="row">
          <div className="small-12 column">
            <FormErrorsBox formName="source-create-form" customErrors={customErrors} />
          </div>
        </div>

        {/* NAME */}
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                label={<FormattedMessage id="general.sourceName" />}
                name="name"
                handleChange={handleNameChange}
                setFarmAttributes={setFarmAttributes}
                isFarmSet={!!farmAttributes.id}
                component={FarmAutosuggestField}
              />
            </label>
          </div>
        </div>

        {isExistingFarm && !farmAttributes.external_source_assigned && (
          <MessageBox type="success" icon={<i className="fa fa-info-circle" />}>
            <FormattedMessage
              id="component.messageBox.sourceCreateWarning"
              values={{
                b: (text) => <b>{text}</b>,
                farmName: name,
              }}
            />
          </MessageBox>
        )}

        {isExistingFarm && farmAttributes.external_source_assigned && (
          <MessageBox type="alert">
            <FormattedMessage
              id="component.messageBox.sourceWithFarmExistWarning"
              values={{ b: (text) => <b>{text}</b> }}
            />
          </MessageBox>
        )}

        {isNewFarm && (
          <>
            <div className="row">
              <div className="small-12 column">
                <label>
                  <AddressSelect
                    label={<FormattedMessage id="general.address" />}
                    hasPoweredByLabel
                    selected={selected}
                    countriesData={countries}
                    changeValue={
                      (fieldName, val) => handleAddressAndCountryBorn(
                        change, `farm_attributes.${fieldName}`, val, 'farm_attributes.country_born'
                      )
                    }
                    placeholder={<FormattedMessage id="general.typeAddress" />}
                  />
                </label>
              </div>
            </div>

            <FederalPremiseIDField className="row mt-15" name="farm_attributes.federal_premise_id" />
            <FarmTypeField className="mt-15" name="farm_attributes.farm_type" />
            <FarmManagerField name="farm_attributes.manager_id" currentCompany={currentCompany} />
          </>
        )}
      </div>

      {isNewFarm && (
        <div className="small-12 medium-6 large-8 column">
          <div className={cn('map-block')}>
            <GoogleMapSelect
              onChangeAdress={changeAddress}
              hasLocationDefining
              addressData={farmAttributes.addressObj}
            >
              {(position) => <FarmMarker {...position} farm={{ farm_type: farmAttributes.farm_type }} />}
            </GoogleMapSelect>
          </div>
        </div>
      )}

      <div className="small-12 column">
        {/* BUTTON */}
        <div className="row mt-5 mb-20">
          <div className="small-12 column">
            <Button
              primary
              type="submit"
              disabled={pristine || submitting || !name || (name && farmAttributes.external_source_assigned)}
            >
              <FormattedMessage id="general.createSource" />
            </Button>
            <Link to="/sources" className="button btn-link margin-left-1">
              <FormattedMessage id="general.button.cancel" />
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

SourceForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  name: T.string,
  farmAttributes: T.object,
  currentCompany: T.object.isRequired,
};

const selector = formValueSelector('source-create-form');

const formComponent = reduxForm({ form: 'source-create-form' })(SourceForm);

export default connect(
  (state) => ({
    name: selector(state, 'name'),
    farmAttributes: selector(state, 'farm_attributes') || {},
    currentCompany: state.auth.user.current_company,
  }),
  { change }
)(formComponent);
