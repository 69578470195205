import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { CustomColumn } from 'components/DataTable/Columns';
import DataTable from 'components/DataTable/DataTable';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
import { Link } from 'react-router';
import { FeedOrderStatusBadge } from 'components/StatusBadge/StatusBadge';
import InfoTooltip from 'components/InfoTooltip';
// utils
import moment from 'moment';
import { tableNames } from 'utils/constants';
import { formatNumber } from 'utils';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getGroupFeedOrders } from 'endpoints/pigGroups';

const fetchGroupFeedOrders = (groupId, params) => {
  return getGroupFeedOrders(groupId, params);
};

const GroupFeedOrders = ({
  tableParams,
  setTableParams,
  setPageOptions,
  setBreadcrumbs,
  params: { groupId, farmId },
  pigGroup
}) => {
  const {
    onPageChange,
    onPerPageChange,
    onSortChange,
    fetchData,
    data: { resources, isLoading, meta: { total } },
  } = useDataTable((params) => fetchGroupFeedOrders(groupId, params), {
    setTableParams: (params) => setTableParams(tableNames.groupFeedOrders, params),
    tableParams,
  });

  useEffect(() => {
    fetchData(tableParams);
    setPageOptions({ backLink: `/feed-orders-farms/${farmId}/groups` });
  }, [farmId, groupId]);

  useEffect(() => {
    if (pigGroup && pigGroup.farm) {
      setBreadcrumbs([
        {
          label: <FormattedMessage id="general.feedOrders" />,
          path: '/feed-orders-farms',
        },
        {
          label: pigGroup.farm.name || '',
          path: `/feed-orders-farms/${farmId}/groups`,
        },
        {
          label: pigGroup.name || '',
          path: `/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders`,
          useLabelAsMobileTitle: true,
        },
      ]);
    }
  }, [pigGroup, farmId, groupId]);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: parseInt(total, 10),
    currentPage: parseInt(tableParams.page, 10),
    perPage: parseInt(tableParams.per_page, 10),
  };

  const renderBinNamesColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.binID" />}>
      {
        data.bin_names && data.bin_names.length ? data.bin_names.join(', ')  :
        <FormattedMessage id="general.notAvail" />
      }
    </CustomColumn>
  );

  const renderDeliveryDateColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="general.deliveryDate" />}>
      {
        data.delivery_date ? moment(data.delivery_date).format('MM/DD/YYYY') :
        <FormattedMessage id="general.notAvail" />
      }
    </CustomColumn>
  );

  const renderQuantityColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.orderQuantity" />}>
      {data.quantity ? (
        <span>
          {formatNumber(data.quantity)} <FormattedMessage id="general.weight.lbs" />
        </span>
      ) : (
        <FormattedMessage id="general.notAvail" />
      )}
    </CustomColumn>
  );

  const renderNotesColumn = (data) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="general.comment" />}>
      {data.notes || <FormattedMessage id="general.notAvail" />}
    </CustomColumn>
  );

  const renderStatusColumn = (data) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <FeedOrderStatusBadge status={data.status} />
    </CustomColumn>
  );

  const columns = [
    {
      label: <FormattedMessage id="general.deliveryDate" />,
      flex: '1 1 140px',
      renderer: renderDeliveryDateColumn,
      sortKey: 'delivery_date'
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.binID" />,
      flex: '1 1 140px',
      renderer: renderBinNamesColumn,
      sortKey: 'bin_names_array'
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.orderQuantity" />,
      flex: '1 1 140px',
      renderer: renderQuantityColumn,
      sortKey: 'total_quantity'
    },
    {
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormattedMessage id="component.dataTable.headers.status" />
          <InfoTooltip
            className="ml-5"
            overlay={<FormattedMessage id="container.GroupFeedOrders.hint" />}
          />
        </div>
      ),
      flex: '1 1 140px',
      renderer: renderStatusColumn,
      sortKey: 'status'
    },
    {
      label: <FormattedMessage id="general.comment" />,
      flex: '1 1 140px',
      renderer: renderNotesColumn
    },
  ];

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading className="farms-panel-title" title={<FormattedMessage id="general.feedOrders" />}>
          <Link
            to={`/feed-orders-farms/${farmId}/groups/${groupId}/feed-orders/create`}
            className="button small primary wider"
          >
            <FormattedMessage id="general.button.addFeedOrder" />
          </Link>
        </Panel.Heading>
        <Panel.Body noPadding>
          <DataTable
            sort={tableParams.sort}
            data={resources}
            columns={columns}
            paginationProps={paginationProps}
            isLoading={isLoading}
            onSortChange={onSortChange}
            scrollable
          />

          <NothingBox display={!resources.length} isLoading={isLoading}>
            <FormattedMessage tagName="h2" id="component.nothingBox.noData" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupFeedOrders.propTypes = {
  tableParams: T.object.isRequired,
  params: T.object.isRequired,
  setTableParams: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  pigGroup: T.object,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.groupFeedOrders),
    pigGroup: state.profiles.pigGroup.data,
  }), {
    setTableParams,
    setPageOptions,
    setBreadcrumbs,
  }
)(GroupFeedOrders);
