import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setAnswer } from 'reducers/siteVisitReport';
// components
import { UnmountClosed } from 'react-collapse';
import { FormattedMessage } from 'react-intl';
import QuestionComments from '../QuestionComments';
import QuestionNewComment from '../QuestionNewComment';
import QuestionAnswerIcon from '../QuestionAnswerIcon';
import { ReactComponent as PencilIcon } from './pencil.svg';
import Button from 'components/Button';
// constants
import { answerButtons, answerValues } from './constants';
// utils
import classnames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
// styles
import styles from './QuestionRow.module.scss';

const cn = classnames.bind(styles);

const QuestionRow = ({ setAnswer, question, questionIndex, categoryIndex }) => {
  const [isNoteOpen, setNoteOpen] = useState(() => isMobile && question.open_answer);
  const answer = question.answer?.value;
  const [openAnswerText, setOpenAnswerText] = useState(answer || '');

  const toggleNote = () => {
    if (answer || question.open_answer) {
      setNoteOpen(!isNoteOpen);
    }
  };

  const onAnswerChange = (value) => {
    if (value !== answerValues.fail) {
      setAnswer({ value, is_flagged: false });
    } else {
      setAnswer({ value });
      setNoteOpen(true);
    }
  };

  const handleSaveOpenAnswer = () => {
    onAnswerChange(openAnswerText);
  };

  const renderAnswerButton = (buttonData) => (
    <div
      key={buttonData.value}
      onClick={() => onAnswerChange(buttonData.value)}
      className={cn('btn', buttonData.value, { selected: answer === buttonData.value })}
    >
      <FormattedMessage id={buttonData.labelKey} />
    </div>
  );

  return (
    <div className={cn('question-row', { flagged: question.answer?.is_flagged })}>
      <div className={cn('header')}>
        <div className={cn('info-column')}>
          <QuestionAnswerIcon answerValue={answer} questionIndex={questionIndex} openAnswer={question.open_answer} />
          <span className={cn('ml-10')}>{question.label}</span>
        </div>

        <div className={cn('btn-group')}>
          {!question.open_answer && answerButtons.map(renderAnswerButton)}
          <div
            onClick={toggleNote}
            className={cn('note-btn', { disabled: !answer && !question.open_answer, opened: isNoteOpen })}
          >
            {isNoteOpen ? <i className={cn('fa fa-times')} /> : <PencilIcon />}
          </div>
        </div>
      </div>

      <UnmountClosed isOpened={isNoteOpen}>
        {question.open_answer ? (
          <div className={cn('note-section')}>
            <FormattedMessage id="general.typeResponse">
              {(placeholder) => (
                <textarea
                  className="textArea"
                  placeholder={placeholder}
                  value={openAnswerText}
                  onChange={(e) => setOpenAnswerText(e.target.value)}
                />
              )}
            </FormattedMessage>

            <div className={cn('note-actions')}>
              <Button
                onClick={handleSaveOpenAnswer}
                disabled={openAnswerText === answer}
                className={cn('save-btn')}
                primary
              >
                <FormattedMessage id="general.button.save" />
              </Button>
            </div>
          </div>
        ) : (
          <>
            <QuestionComments
              categoryIndex={categoryIndex}
              questionIndex={questionIndex}
              question={question}
            />
            <QuestionNewComment
              categoryIndex={categoryIndex}
              questionIndex={questionIndex}
              setAnswer={setAnswer}
              question={question}
            />
          </>
        )}
      </UnmountClosed>
    </div>
  );
};

QuestionRow.propTypes = {
  question: T.object.isRequired,
  categoryIndex: T.number.isRequired,
  questionIndex: T.number.isRequired,
  setAnswer: T.func.isRequired,
};

export default connect(
  (state) => ({
    farmId: state.siteVisitReport.farm.id,
  }), (dispatch, { questionIndex, categoryIndex  }) => ({
    setAnswer: (answer) => dispatch(setAnswer(categoryIndex, questionIndex, answer)),
  })
)(QuestionRow);
