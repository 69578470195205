import React, { useState } from 'react';
import T from 'prop-types';
// components
import CircleCheckbox from 'components/CircleCheckbox';
import Panel from 'components/Panel';
import Field from 'components/Field';
import { FormattedMessage } from 'react-intl';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import Divider from 'components/Divider';
import Button from 'components/Button';
import SearchBox from 'components/SearchBox';
import { DragDropContext } from 'react-beautiful-dnd';
import NothingBox from 'components/NothingBox';
import CreateCategoryModal from '../CreateCategoryModal';
import DroppableCategory from '../DroppableCategory';
import ToggleSwitcher from 'components/ToggleSwitcher';
import { GroupFrequencyForm, FarmFrequencyForm, DisabledFrequencyForm } from './ReportFrequency';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
// constants
import {
  reportTemplateFarmfeedTriggers,
  reportTemplateFormName
} from './constants';
// utils
import classnames from 'classnames/bind';
// redux
import { reduxForm, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// utils
import { frequencyTypes, reorder } from './helper';
// styles
import styles from './ReportTemplateForm.module.scss';

const farmTypes = [
  { label: <FormattedMessage id="general.nursery" />, value: 'n' },
  { label: <FormattedMessage id="general.finisher" />, value: 'f' },
  { label: <FormattedMessage id="general.sowUnit" />, value: 'su' },
  { label: <FormattedMessage id="general.weanToFinish" />, value: 'wf' }
];

const cn = classnames.bind(styles);

const ReportTemplateForm = ({
  title,
  onSubmit,
  onCancel,
  formValues,
  openModal,
  change,
  validate,
  submitButtonLabel,
}) => {
  const {
    farmfeed_event,
    svr_categories,
    general_notes_section,
    action_plan_section,
    photo_video_uploads,
    frequency_type,
    disabled_farm_types,
  } = formValues;
  const [search, setSearch] = useState('');
  const handleSubmit = () => onSubmit(formValues);
  const onCategoryCreate = (category) => change('svr_categories', [...svr_categories, category]);
  const handleCategoryCreate = () => openModal(<CreateCategoryModal onSubmit={onCategoryCreate} />);

  const updateQuestionsSequence = async (dndResult) => {
    const { source, destination } = dndResult;
    const shallowCopy = [...svr_categories];
    const categoryKey = destination.droppableId;
    const categoryIndex = shallowCopy.findIndex(({ key }) => key === categoryKey);
    const category = shallowCopy.find(({ key }) => key === categoryKey);

    try {
      const reorderedQuestionsData = reorder(category.svr_questions, source.index, destination.index);
      change(`svr_categories[${categoryIndex}].svr_questions`, reorderedQuestionsData);
    } catch (error) {
      change(`svr_categories[${categoryIndex}].svr_questions`, category.svr_questions);
    }
  };

  const handleFarmTypeToggle = (farmType) => () => {
    const farmTypeIndex = disabled_farm_types?.findIndex((type) => type === farmType);
    const updatedFarmTypes = farmTypeIndex >= 0 ?
      [...disabled_farm_types.slice(0, farmTypeIndex), ...disabled_farm_types.slice(farmTypeIndex + 1)] :
      [...disabled_farm_types, farmType];
    change('disabled_farm_types', updatedFarmTypes);
  };

  const onDragEnd = (result) => (!result.destination || result.destination.index === result.source.index ?
    undefined :
    updateQuestionsSequence(result));

  const farmFeedEventTriggerLabel = reportTemplateFarmfeedTriggers.find(({ value }) => value === farmfeed_event)?.label;

  const categoryRenderer = (data, index) => (
    <DroppableCategory search={search} categoryData={data} categoryIndex={index} key={index} />
  );

  return (
    <section className="small-12 column">
      <Panel className={cn('report-template-form')}>
        <Panel.Heading className="ph-30" title={title} />
        <Panel.Body className="ph-30">
          {/* NAME */}
          <div className={cn('medium-4')}>
            <FormattedMessage id="general.reportName">
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id="general.reportName" />}
                  name="name"
                  placeholder={placeholder}
                  className={cn('roles-field-input')}
                />
              )}
            </FormattedMessage>
          </div>

          {/* FREQUENCY TYPE */}
          <div className={cn('medium-4', 'row')}>
            <Field
              label={<FormattedMessage id="general.reportFrequency" />}
              name="frequency_type"
              component={SelectField}
              options={frequencyTypes}
              valueKey="value"
              labelKey="label"
            />
          </div>

          {/* FREQUENCY */}
          {frequency_type === 'never' && <DisabledFrequencyForm />}
          {frequency_type === 'by_farm' && <FarmFrequencyForm />}
          {frequency_type === 'by_group' && <GroupFrequencyForm />}

          {/* DISABLE SVR FOR */}
          <Divider className="mt-20 mb-20" />
          <div className="semibold">
            <FormattedMessage id="general.disableReport" />
          </div>
          <div className="description pb-10">
            <FormattedMessage id="general.disableReportForFarmTypeDesc" />
          </div>
          {farmTypes.map(({ label, value }, index) => (
            <div key={index} className="pb-10">
              <CircleCheckbox
                type="checkbox"
                checked={disabled_farm_types?.includes(value)}
                label={<span>{label} ({value.toUpperCase()})</span>}
                onChange={handleFarmTypeToggle(value)}
              />
            </div>
          ))}

          {/* FF EVENTS */}
          <Divider className="mt-20 mb-20" />
          <div className={cn('ff-trigger')}>
            <div className={cn('title')}>
              <FormattedMessage
                tagName="b"
                id="general.triggerFarmfeedEvents"
                values={{ value: <b className="primary">{farmFeedEventTriggerLabel}</b> }}
              />
              <p className={cn('description')}>
                <FormattedMessage id={`general.triggerFarmfeedEvents.desc.${farmfeed_event}`} />
              </p>
            </div>
            <Field
              name="farmfeed_event"
              options={reportTemplateFarmfeedTriggers}
              className={cn('radio-group')}
              component={({ input, ...rest }) => <ButtonRadioGroup {...input} {...rest} />}
            />
          </div>
          <Divider className="mt-20 mb-20" />
          {/* ADDITIONAL SETTINGS */}
          <ToggleSwitcher
            className="mb-30"
            onChange={({ target: { checked } }) => change('general_notes_section', checked)}
            checked={!!general_notes_section}
            text={<FormattedMessage id="general.generalNotesSection" />}
          />
          <ToggleSwitcher
            className="mb-30"
            onChange={({ target: { checked } }) => change('action_plan_section', checked)}
            checked={!!action_plan_section}
            text={<FormattedMessage id="general.actionPlanSection" />}
          />
          <ToggleSwitcher
            className="mb-30"
            onChange={({ target: { checked } }) => change('photo_video_uploads', checked)}
            checked={!!photo_video_uploads}
            text={<FormattedMessage id="general.allowPhotoVideoUploads" />}
          />
          <Divider className="mt-20 mb-20" />

          {/* CATEGORIES */}
          <NothingBox display={!svr_categories.length}>
            <span className={styles['nothing-placeholder-title']}>
              <h4><FormattedMessage id="component.nothingBox.anyCategoriesYet" /></h4>
            </span>
            <FormattedMessage id="component.nothingBox.createCategory" />
            <Button onClick={handleCategoryCreate} primary type="button" className="mt-10">
              <FormattedMessage id="component.modal.reports.createCategory" />
            </Button>
          </NothingBox>

          <div className="medium-8">
            <div className={cn('categories-container')}>
              {!!svr_categories.length && (
                  <>
                    <div className={cn('categories-container-header', 'mb-20')}>
                      <FormattedMessage id="general.search.questions">
                        {(placeholder) => (
                          <SearchBox
                            placeholder={placeholder}
                            className={cn('search-box')}
                            initialValue={search}
                            disableFocus
                            onChange={setSearch}
                          />
                        )}
                      </FormattedMessage>
                      <Button
                        type="button"
                        onClick={handleCategoryCreate}
                        className={cn('create-category-btn', 'ml-20')}
                        primary
                      >
                        <FormattedMessage id="component.modal.reports.createCategory" />
                      </Button>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      {svr_categories.map(categoryRenderer)}
                    </DragDropContext>
                </>
              )}
            </div>
          </div>

          {/* BUTTON GROUP */}
          <div className={cn('form-footer')}>
            <Divider />
            <div className={cn('btn-group')}>
              <Button type="button" onClick={onCancel} className={cn('btn', 'mr-20')}>
                <FormattedMessage id="general.button.cancel" />
              </Button>
              <Button
                disabled={!validate?.(formValues)}
                onClick={handleSubmit}
                primary
                className={cn('btn')}
              >
                {submitButtonLabel}
              </Button>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

const formComponent = reduxForm({
  form: reportTemplateFormName,
  enableReinitialize: true,
  initialValues: {
    frequency: '',
    frequency_unit: '',
    frequency_type: 'never',
    farmfeed_event: 'none',
    svr_categories: [],
    general_notes_section: false,
    action_plan_section: false,
    photo_video_uploads: false,
    disabled_farm_types: [],
  },
})(ReportTemplateForm);

ReportTemplateForm.propTypes = {
  submitButtonLabel: T.node.isRequired,
  title: T.node.isRequired,
  onSubmit: T.func.isRequired,
  onCancel: T.func.isRequired,
  formValues: T.object,
  validate: T.func,
};

ReportTemplateForm.defaultProps = {
  formValues: {
    farmfeed_event: 'none',
    svr_categories: [],
  },
};

export default connect((state) => ({
  formValues: getFormValues(reportTemplateFormName)(state),
}), { openModal, change })(formComponent);
